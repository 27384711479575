// 40px
$size-1: 2.5rem
// 24px
$size-2: 1.5rem
// 20px
$size-3: 1.25rem
// 16px
$size-4: 1rem
// 14px
$size-5: 0.875rem
// 12px
$size-6: 0.75rem
// 10px
$size-7: 0.625rem
//
$size-8: 0.5rem
