$blue: #1976D2;
$black: #000000;
$indigo: #6610f2;
$purple: #A358F9;
$pink: #d640c1;
$red: #E82647;
$deep-orange: #FF6F00;
$light-orange: #FFE9D1;
$orange: #FFAA00;
$yellow: #FFC400;
$green: #5BAF42;
$teal: #0EBEF5;
$cyan: #17a2b8;
$white: #ffffff;
$gray: #5B6070;

$primary: $blue;
$success: $green;
$info: $teal;
$warning: $yellow;
$danger: $red;

$theme-colors: (
        "black": $black,
        "white": $white,
        "primary": $primary,
        "success": $success,
        "info": $info,
        "warning": $warning,
        "danger": $danger,
        "deepOrange": $deep-orange,
        "teal": $teal,
        "pink": $pink,
        "purple": $purple,
        "gray": $gray,
        "yellow": $yellow
);

@function theme-color($key: "primary") {
  @return map-get($theme-colors, $key);
}

@each $key, $value in $theme-colors {
  :export {
    #{unquote($key)}: $value;
    #{unquote($key)}Light: lighten($value, 40%);
  }
}

:export {
  #{unquote("purple")}Light: lighten($purple, 30%);
  #{unquote("primary")}Light: lighten($primary, 50%);
  #{unquote("gray")}Light: lighten($gray, 53%);
  #{unquote("gray")}Border: lighten($gray, 40%);
}

