@import "../../styles/colors.module"
@import "../../styles/variables"

.additionalFiles
  overflow: hidden
  text-overflow: ellipsis
  margin-bottom: 5px

.document-link
  margin-right: 1rem
  cursor: pointer
  text-decoration: underline
.detail-panel-wrapper
  padding: 20px 40px
  margin-top: 0 !important
  background-color: lighten($gray, 53%)
  font-size: $size-6
  color: $gray
.detail-panel-box
  background-color: $white
  overflow: hidden
  &.left-rounded
    border-top-left-radius: 5px
    border-bottom-left-radius: 5px

  &.right-rounded
    border-top-right-radius: 5px
    border-bottom-right-radius: 5px
  &.rounded
    border-radius: 5px
  &.todo-list
    margin-bottom: 20px
#nested-list-subheader
  background-color: lighten($gray, 56%)
  font-weight: bold !important
.detail-panel-section-title
  color: lighten($gray, 40%)
  size: $size-7
  text-transform: uppercase
.detail-panel-gray-color-section
  color: lighten($gray, 40%)

.offers-side-tab-gray-box
  color: $gray
  padding: 3px 0px
  border: 1px solid lighten($gray, 40%)
  border-radius: 5px
  background-color: lighten($gray, 58%)
  margin: 20px

.offer-box
  padding: 0 10px
  margin: 10px 0 !important
  &.right-border
    border-right: 1px solid lighten($gray, 40%)

.title-casco-plus-go
  margin-right: 5px
