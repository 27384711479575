@import "colors.module";
.toast-error {
  background-color: #FF0000;
  border-radius: 5px;
}

.toast-success {
  background-color: #5cd04c;
  border-radius: 5px;
}
.toast-warning {
  background-color: $orange;
  border-radius: 5px;
}

.toast-body {
  color: white;
  font-size: 12px;

}

.toast-warning, .toast-error, .toast-success button{ color: white;}
.toast-warning, .toast-error, .toast-success b {
  font-size: 16px;
  font-weight: 500;
}

.Toastify__toast-container--bottom-right {
  right: 2em;
}

.Toastify__toast-container {
  width: 357px;
}

.Toastify__toast {
  padding: 11px 11px 10px 14px;
}
