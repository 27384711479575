.form-control {
  width: 80%;
}
.form-control.is-half div:first-child{
  flex-direction: row;
  justify-content: space-between;

}
.form-control.is-half{
  width: 100%;
}
.form-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}
.label-wrapper {
  width: 48%;
  margin: 10px 2px;
  border-radius: 50px;
  border: 2px solid transparent;
}
.label-wrapper.selected {
  //border: 2px solid $green;
}
.label {
  text-align: left;
  margin: 0;
  border: 1px solid #DCDCDC;
  background-color: #F5F5F5;
  width: 100%;
  border-radius: 50px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  max-height: 51px;
  overflow: hidden;
}
.label.selected {
  //border: 1px solid transparent;
  //background-color: #F2F9EF;
}
.label-text {
  text-align: left;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1;
}
.input {
  display: none;
}
.svg-wrapper{
  padding: 9px;
  line-height: 0.1rem;
}

@media (max-width: 768px) {
  .form-control {
    width: 100%;
  }
  .label-wrapper {
    width: 100%;
  }
}
