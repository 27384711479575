@import "colors.module"
@import "variables"

html, body
  height: 100%

body
  background-color: lighten($gray, 53%) !important
  color: $gray !important
  margin: 0
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale


code
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',monospace
.link
  cursor: pointer
  text-decoration: underline
  color: $primary

.vertical-align
  display: flex
  align-items: center

.horizontally-centered
  display: flex
  justify-content: center
.space-between
  display: flex
  justify-content: space-between
.mr-5
  margin-right: 5px

.font-small
  font-size: 10px

.bold
  font-weight: bold !important

.break
  flex-basis: 100%
  height: 0


th.MuiTableCell-head
  font-size: 0.7rem

td.MuiTableCell-sizeSmall
  padding-left: 3px
  padding-right: 3px

td.MuiTableCell-paddingNone.MuiTableCell-sizeSmall
  padding-left: 0
  padding-right: 0

.red
  color: $danger
.orange
  color: $orange


.size-1
  font-size: $size-1
.size-2
  font-size: $size-2
.size-3
  font-size: $size-3
.size-4
  font-size: $size-4
.size-5
  font-size: $size-5
.size-6
  font-size: $size-6
.size-7
  font-size: $size-7 !important
.size-8
  font-size: $size-8 !important

.has-text-centered
  text-align: center
.has-text-primary
  color: $primary !important
.has-text-success
  color: $green !important
.has-text-danger
  color: $red !important

.inline-block
  display: inline-block
.block
  display: block
.cursor-pointer
  cursor: pointer
  
.tableNew [aria-haspopup="listbox"]
  opacity: 0