@import "../../styles/colors.module"
@import "../../styles/variables"

.status-icon
  color: $gray
  font-size: 7px !important
  margin-right: 5px
  &.warning
    color: $warning
  &.danger
    color: $danger
  &.success
    color: $success
  &.info
    color: $info
  &.primary
    color: $primary
  &.gray-light
    color: lighten($gray, 40%)
.status-text
  font-size: $size-6
  color: $gray
  &.warning
    color: $warning
  &.danger
    color: $danger
  &.success
    color: $success
  &.info
    color: $info
  &.primary
    color: $primary
  &.gray-light
    color: lighten($gray, 40%)
