@import "../../../styles/colors.module"
@import "../../../styles/variables"

.backoffice-status-icon
  color: $gray
  font-size: 7px !important
  margin-right: 5px
  &.warning
    color: $warning
  &.danger
    color: $danger
  &.success
    color: $success
  &.info
    color: $info
  &.primary
    color: $primary
  &.size-big
    font-size: 15px !important
.backoffice-status-text
  font-size: $size-7
  color: $gray
  &.warning
    color: $warning
  &.danger
    color: $danger
  &.success
    color: $success
  &.info
    color: $info
  &.primary
    color: $primary
