@import "../../../styles/variables"
@import "../../../styles/colors.module"
.chip
  font-size: $size-7,
  color: $white
  border-radius: 25px
  padding: 1px 15px
  margin-left: 5px
.button-tab
  font-size: $size-5 !important
  font-weight: bold !important
  color: $gray !important
  background-color: $white !important
  padding: 15px 25px !important
  .chip
    background: lighten($primary, 30%)
.button-tab.selected
  color: $white !important
  background-color: $primary !important
  .chip
    background: darken($primary, 5%)




