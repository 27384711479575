@import "../../styles/colors.module"
@import "../../styles/variables"


.send-documents-gray-box
  color: $gray
  padding: 3px 10px
  border: 1px solid lighten($gray, 40%)
  border-radius: 5px
  background-color: lighten($gray, 58%)
  margin-bottom: 10px
.pdf-wrapper
  padding: 10px 5px
  overflow: hidden
  svg
    color: $red
    margin-right: 5px
  span
    overflow: hidden
    display: inline-block
    width: 80%
    text-overflow: ellipsis
