@import "../../../styles/colors.module"
@import "../../../styles/variables"

.paragraph
  font-size: $size-4
  padding-left: 10px
.edit-policy-loading-wrapper
  color: grey
  font-size: $size-5
  text-align: center
.edit-policy-box
  border: 1px solid lighten($gray, 40%)
  border-radius: 12px
  margin-top: 20px
.edit-policy-cancelled-docs-box
  margin-bottom: 10px
  p
    font-size: $size-5
    padding-left: 10px
    margin: 0
.edit-policy-duepayments-section-title
  font-size: $size-3
  background-color: lighten($gray, 50%)
  text-align: center
  padding: 10px 0

.edit-policy-add-duepayments-wrapper
    display: flex
    flex-grow: 1
    margin-top: 25px
    flex-direction: column
    width: 50%
    border: 1px solid lighten($gray, 40%)
    border-radius: 12px
    padding: 10px
    button
      color: #fff !important
      width: min-content
      align-self: flex-end
