@import "../../../styles/colors.module"
@import "../../../styles/variables"


th.MuiTableCell-sizeSmall
  span.MuiTableSortLabel-root
    width: 100%
th.MuiTableCell-sizeSmall
  div
    width: 100%
    border-radius: 3px
    border: 1px solid lighten($gray, 53%)
    padding: 2px 5px
    color: $gray
    min-height: 30px

.gray-text
  color: $gray
tr.MuiTableRow-root:first-child
  td
    border-bottom: none
    height: 0
    padding: 0
    margin: 0
    .MuiFormLabel-root
      font-size: 0.7rem
      margin-top: -9px !important
tr.MuiTableRow-root:first-child .MuiInput-root.MuiInput-underline::after, tr.MuiTableRow-root:first-child .MuiInput-root.MuiInput-underline:before
  border-bottom: none !important
tr.MuiTableRow-root.MuiTableRow-footer
  td
    height: auto
//div[class^='Component-horizontalScrollContainer'],
div:has(+ table):nth-last-of-type(1)
  padding: 40px 20px
