@import "../../styles/colors.module"
@import "../../styles/variables"

.inner-tab-item
  background-color: #E1EFFF !important
  color: $gray!important
  font-size: $size-6 !important
  font-weight: bold !important
  border-top-left-radius: 5px!important
  border-top-right-radius: 5px!important
  margin-right: 3px !important
  &.selected
    background-color: $white !important
    color: $primary !important
  //&:last-of-type
  //  margin-right: 0 !important
