@import "../../../styles/colors.module"
@import "../../../styles/variables"


.shipment-details-box
  padding: 20px
  border: 1px solid lighten($gray, 40%)
  border-radius: 12px
  height: 100%

.shipment-gray-box
  color: $gray
  padding: 10px
  border: 1px solid lighten($gray, 40%)
  border-radius: 5px
  background-color: lighten($gray, 58%)
  &.switch-padding
    padding: 5px 20px
  &.has-delivery-switch
    width: 400px
