@import "../../styles/colors.module"
@import "../../styles/variables"


.wizard-navigation
  display: flex
  justify-content: center
  margin: 10px
.stepper
  background-color: lighten($gray, 53%)
.modal-title-in-wizard
  margin: 20px 0
  text-align: center
#row-radio-buttons
  margin-right: 10px
  color: $gray
.move-to-issued-title, .policy-created-title
  color: $success
  text-align: center

